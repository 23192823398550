import { Tooltip, TooltipProps } from '@components/common/Tooltip/Tooltip';
import { cloneElementWithClass } from '@utils';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

export type LinkSize = 'sm' | 'md' | 'lg';

type Props = {
  children: ReactNode;
  href?: string;
  asButton?: boolean;
  onClick?: () => void;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  disabledTooltip?: TooltipProps;
  /**
   * Based on Tailwind font size
   * @see https://tailwindcss.com/docs/font-size
   */
  fontSize?: string;
  /**
   * Based on Tailwind text color class
   * @see https://tailwindcss.com/docs/text-color
   */
  textColor?: string;
  size?: LinkSize;
};

const fontSizeMap: Record<LinkSize, string> = {
  sm: 'text-xs',
  md: 'text-sm',
  lg: 'text-base',
};

export const Link = ({
  href,
  children,
  asButton,
  onClick,
  icon,
  iconPosition = 'right',
  disabled,
  disabledTooltip,
  textColor,
  size = 'md',
}: Props) => {
  if (asButton) {
    if (!onClick) {
      throw new Error('Link: onClick is required when asButton is true');
    }
    if (href) {
      throw new Error(
        'Link: href has no effect when asButton is true, use onClick instead',
      );
    }
  }

  if (disabled && !disabledTooltip) {
    throw new Error('Link: disabledTooltip is required when disabled');
  }

  const Icon = icon ? cloneElementWithClass(icon, 'w-5 h-5') : null;

  const content = (
    <div
      className={clsx(
        'flex items-center gap-2',
        !disabled && 'hover:opacity-80',
        size && fontSizeMap[size],
      )}
    >
      {iconPosition === 'left' && Icon}
      {asButton ? (
        <button
          onClick={(event) => {
            event.preventDefault();
            onClick?.();
          }}
          disabled={disabled}
          className={clsx(
            'font-semibold underline focus:outline-none focus:ring-2 focus:ring-product-dark focus:ring-offset-2 focus-visible:ring-product-dark disabled:text-gray-500',
            textColor,
          )}
        >
          {children}
        </button>
      ) : (
        <ReactRouterLink
          to={href ?? '#'}
          className={clsx(textColor, size && fontSizeMap[size], 'underline')}
        >
          {children}
        </ReactRouterLink>
      )}
      {iconPosition === 'right' && Icon}
    </div>
  );

  if (disabled && disabledTooltip) {
    return <Tooltip {...disabledTooltip}>{content}</Tooltip>;
  }

  return content;
};
