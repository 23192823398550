import { NavBar } from '@components/common/Navigation/NavBar';
import { PropsWithChildren } from 'react';

export function TopBarNavigationLayout({ children }: PropsWithChildren) {
  return (
    <div className="relative h-screen">
      <NavBar />
      <main className="h-full overflow-x-hidden pt-16 sm:overflow-auto">
        {children}
      </main>
    </div>
  );
}
