import { IconButton } from '@components/common/IconButton/IconButton';
import { StyluxLogo } from '@components/common/StyluxLogo';
import { Disclosure } from '@headlessui/react';
import { useUser } from '@hooks/useUser';
import { mainNavigation, userNavigation } from '@routes/nav-routes';
import { isModeE2E } from '@utils';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { Badge } from '../Badge/Badge';
import { MobileMenuButton } from './MobileMenuButton';
import { MobileNav } from './MobileNav';
import { UserMenu } from './UserMenu';

export const NavBar = () => {
  const { user } = useUser();
  const { pathname } = useLocation();

  return (
    <Disclosure
      as="nav"
      className="fixed left-0 right-0 top-0 z-50 border-b border-gray-200 bg-white"
    >
      {({ open }) => (
        <>
          <div className="mx-auto px-2 sm:px-6 lg:px-20">
            <div className="flex h-16 justify-between">
              <div className="flex gap-6">
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/" className="hover:opacity-60">
                    <StyluxLogo className="mx-2 sm:mx-0" />
                  </Link>
                </div>
                <div className="hidden sm:flex sm:space-x-8">
                  {mainNavigation
                    .filter((item) =>
                      item.roles ? user.hasRole(item.roles) : true,
                    )
                    .map((item) => (
                      <div
                        key={item.name}
                        className="inline-flex h-full items-center"
                      >
                        <Link
                          to={item.href ?? '#'}
                          className={clsx(
                            'relative flex h-full items-center border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-900 hover:border-gray-300',
                            (item.href === '/'
                              ? pathname === '/'
                              : pathname.startsWith(item.href ?? '')) &&
                              'border-b-product-dark hover:border-b-product-dark',
                          )}
                        >
                          {item.name}
                        </Link>
                      </div>
                    ))}
                </div>
              </div>

              <div className="relative hidden items-center justify-between gap-5 sm:ml-6 sm:flex sm:items-center">
                {isModeE2E && (
                  <Badge icon={null} variant="info" size="m">
                    E2E MODE
                  </Badge>
                )}
                {userNavigation
                  .filter((item) => item.icon)
                  .map((item) => (
                    <IconButton
                      key={item.name}
                      onClick={() => {
                        // no-op
                      }}
                      icon={item.icon}
                    />
                  ))}

                <UserMenu user={user} />
              </div>

              <div className="flex items-center sm:hidden">
                <MobileMenuButton isOpen={open} />
              </div>
            </div>
          </div>

          <MobileNav />
        </>
      )}
    </Disclosure>
  );
};
