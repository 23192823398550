import { DisclosureButton } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

type Props = {
  isOpen: boolean;
};

export const MobileMenuButton = ({ isOpen }: Props) => {
  return (
    <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500">
      {isOpen ? (
        <XMarkIcon className="block h-6 w-6 text-gray-900" aria-hidden="true" />
      ) : (
        <Bars3Icon className="block h-6 w-6 text-gray-900" aria-hidden="true" />
      )}
    </DisclosureButton>
  );
};
