import { LogoutButton } from '@components/auth/LogoutButton';
import { DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { useUser } from '@hooks/useUser';
import { mainNavigation, userNavigation } from '@routes/nav-routes';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

export const MobileNav = () => {
  const { pathname } = useLocation();
  const { user } = useUser();

  return (
    <DisclosurePanel className="sm:hidden">
      <div className="flex flex-col gap-1 pb-3 pt-2">
        {mainNavigation
          .filter((item) => (item.roles ? user.hasRole(item.roles) : true))
          .map((item) => (
            <DisclosureButton
              as={Link}
              key={item.name}
              to={item.href ?? '#'}
              className={clsx(
                'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-900',
                pathname === item.href && 'border-l-product-dark bg-gray-100',
              )}
            >
              {item.name}
            </DisclosureButton>
          ))}
      </div>
      <div className="border-t border-gray-200 pb-3 pt-2">
        <div className="flex flex-col gap-2">
          {[...userNavigation].map((item) => (
            <DisclosureButton
              as={Link}
              key={item.name}
              to={item.href ?? '#'}
              className={clsx(
                'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-900',
                pathname === item.href && 'border-l-product-dark bg-gray-100',
              )}
            >
              {item.name}
            </DisclosureButton>
          ))}

          <LogoutButton className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-left text-base font-medium text-gray-900" />
        </div>
      </div>
    </DisclosurePanel>
  );
};
